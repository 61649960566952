.App {
  --frame-border-color: #E8E8E9;
  --light-grey: #f2f2f2;
  --color-6: #ffc000;
  --color-5: #0070c0;
  --color-4: #31859b;
  --continue-green: #3cb030;
  --color-font-1: #0000f1;
  --color-font-2: #a5a9af;
  --warning-red: #ff0000;
  --sidebar-active: #2e344e;
  --sidebar-active-btn-bg: #F4F5F6;
  --sidebar-bg: #031332;

  --chart-red: #ff554b;
  --accent-color: #00A6CD;

  /* New style: */
  --page-bg: #F5F5F5;
  --title-color-dark1: #031332;
  --main-blue: #115DFC;

  --font-family-main: "Assistant-Regular", sans-serif;

  --padding-1: 10px;
}

@font-face {
  font-family: 'Assistant-Regular';
  src: url(./assets/Assistant-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Assistant-Bold';
  src: url(./assets/Assistant-Bold.ttf) format('truetype');
}

.main-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  height: 80vh;
}

.main-body-frame {
  min-width: 400px;
  border: 1px solid var(--frame-border-color);
  padding: 30px;
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-left {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.h-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.v-spacer {
  height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Assistant-Regular', sans-serif;
  margin-bottom: 30px;

}

.enroll-slogan h1 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  color: rgb(3, 19, 50)
}

.enroll-title h1 {
  margin-bottom: 0px;
  font-size: 32px;
  font-weight: 700;
  color: rgb(3, 19, 50);
  font-family: 'Assistant-Bold', sans-serif;
}

.enroll-subtitle p {
  font-size: 18px;
  font-weight: 400;
  color: rgb(91, 93, 98);
  font-family: 'Assistant-Regular', sans-serif;
}

.settings-card-title {
  font-family: 'Assistant-Regular';
}

.settings-card-title h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #031332;
}

.settings-card-title p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5B5D62;
}

.no-margin {
  margin: 0;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.naked-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.red-text {
  color: var(--chart-red) !important;
}

.ltr {
  direction: ltr !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.no-margin-top {
  margin-top: 0;
}

.qa-h1 {
  font-family: 'Assistant-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 24px;
  color: #aaaaaa;
}

.qa-h3 {
  font-family: 'Assistant-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #eeeeee;
}

.qa-p {
  font-family: 'Assistant-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #aaaaaa;
}

.consent-h2 {
  margin-top: 40px;
  margin-bottom: 0px;
  direction: rtl;
  font-family: 'Assistant-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #aaaaaa;
}

.consent-p {
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  direction: rtl;
  font-weight: 400;
  font-size: 16px;
  line-height: 0px;
  margin-block-end: 0em !important;
}

.rtl {
  direction: rtl !important;
}

.img-ratio {
  width: 100% !important;
  object-fit: cover;
}

.img-ratio-100 {
  height: 50px;
  object-fit: cover;
}

.logo-container {
  margin-right: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-qna {
  background-color: #eeeeee;
  padding: 5px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.hover {}

.hover:hover {
  cursor: pointer;
}

.tpp-field {
  width: 100% !important;
  height: 40px;
  border: 0px;
  border-bottom: 2px solid #d9d9d9;
  padding: 0 10px;
  font-size: 16px;
  font-family: 'Assistant-Regular';
  color: #595959;
}

.tpp-field-25 {
  width: 25% !important;
  height: 40px;
  border: 0px;
  border-bottom: 2px solid #d9d9d9;
  padding: 0 10px;
  font-size: 16px;
  font-family: 'Assistant-Regular';
  color: #595959;
}

.heb-font {
  font-family: 'Assistant-Regular' !important;
}

.p-no-margin {
  margin: 0;
}

.no-break-line {
  display: inline-block;
  margin: 0;
}

.font10 {
  font-size: 10px;
}

.percent-90 {
  width: 90%;
}

.percent-100 {
  width: 100%;
}

.width-30 {
  width: 30px;
}

.grey-text {
  color: #595959;
}

.tou-link{
  color: #115DFC;
  text-decoration: underline;
  cursor: pointer;
}

.horizontal-line {
  width: 100%;
  border-bottom: 1px dashed #e0e0e0;
  margin: 40px;
}

.input-field{
  width: 300px;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
  color: #595959;
}